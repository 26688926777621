<template>
  <v-card
    style="border-radius:10px;"
    elevation="2"
  >
    <v-card-text>
      <v-layout>
        <v-flex xs2>
          <v-avatar
            color="#444444"
            size="100"
            tile
            class="hidden-xs-only"
          >
            <v-icon
              x-large
              color="white"
            >
              {{ jobIcon() }}
            </v-icon>
          </v-avatar>
          <v-avatar
            color="#444444"
            tile
            class="hidden-sm-and-up"
          >
            <v-icon
              small
              color="white"
            >
              {{ jobIcon() }}
            </v-icon>
          </v-avatar>
        </v-flex>
        <v-flex xs10>
          <v-layout>
            <v-flex xs7>
              <template v-if="offer.public_url">
                <router-link
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="link_to_offer blue-grey--text text--darken-1 font-weight-black"
                  :to="offer_link"
                  style="text-decoration: None; font-size: 20px;"
                >
                  {{ offer.computed_title }}
                </router-link>
                <router-link
                  v-else
                  class="link_to_offer blue-grey--text text--darken-1 font-weight-black"
                  :to="offer_link"
                  style="text-decoration: None; font-size: 17px;"
                >
                  {{ offer.computed_title }}
                </router-link>
                <v-chip
                  v-if="offer.is_brand_new"
                  label
                  color="cyan darken-1"
                  dark
                  small
                >
                  nuevo
                </v-chip>
                <v-chip
                  v-if="offer.user_is_applying"
                  label
                  color="green"
                  small
                  dark
                >
                  ya postulaste
                </v-chip>
              </template>
              <div
                v-else
                class="headline"
              >
                {{ offer.computed_title }}
                <v-chip
                  color="gray"
                  label
                  small
                  dark
                >
                  cerrado
                </v-chip>
                <v-chip
                  v-if="offer.user_is_applying"
                  color="green"
                  label
                  small
                  dark
                >
                  ya postulaste
                </v-chip>
              </div>

              <h4 class="display-1">
                <template v-if="!offer.is_confidential">
                  {{ offer.company }} -
                </template>
                {{ offer.area_name }} - {{ offer.region_name }}
                <img
                  :src="offer.country_flag_url"
                  :alt="`, ${offer.country_name}`"
                >
              </h4>
              <p class="body-1">
                {{ (offer.summary ? offer.summary : cutString(offer.description)) }}
              </p>
              <template v-if="$vuetify.breakpoint.mdAndDown">
                <p v-if="showPerks">
                  <v-tooltip
                    v-for="(perk, index) in offer.perks"
                    :key="`${perk}-${index}`"
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        {{ perk.icon }}
                      </v-icon>
                    </template>
                    <span>{{ perk.name }}</span>
                  </v-tooltip>
                </p>
              </template>
            </v-flex>
            <v-flex
              xs5
            >
              <div class="text-xs-right">
                <p>
                  <span
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="grey--text"
                  >
                    {{ offer.time_since_created }}
                  </span>
                  <FavouriteButton
                    :offer="offer"
                    @setFavourite="isFavourite => $emit('setFavourite', isFavourite)"
                  />
                </p>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <p v-if="showPerks">
                    <v-tooltip
                      v-for="(perk, index) in offer.perks"
                      :key="`${perk}-${index}`"
                      top
                      style="margin-left: 10px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          {{ perk.icon }}
                        </v-icon>
                      </template>
                      <span>{{ perk.name }}</span>
                    </v-tooltip>
                  </p>
                </template>
                <!-- <p v-if="offer.public_url">
                  <ShareNetwork
                    v-for="network in networks"
                    :key="`offer-social-${offer.uuid}-${network.name}`"
                    :network="network.name"
                    :title="offer.computed_title"
                    :url="offer.public_url"
                  >
                    <v-btn
                      icon
                      outline
                      small
                    >
                      <v-icon small>
                        {{ network.icon }}
                      </v-icon>
                    </v-btn>
                  </ShareNetwork>
                </p> -->
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import FavouriteButton from '@/components/candidates/FavouriteButton.vue';
import SocialNetworksMixin from '@/components/mixins/SocialNetworksMixin.vue';
import cutString from '@/utils/text';

export default {
  name: 'PublicOfferCard',
  components: {
    FavouriteButton,
  },
  mixins: [
    SocialNetworksMixin,
  ],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    fromOfferUuid: {
      type: String,
      required: false,
      default: '',
    },
    disableFavourite: {
      type: Boolean,
      default: false,
    },
    showPerks: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offer_link() {
      const link = {
        name: 'candidate:offer',
        params: {
          uuid: this.offer.uuid,
        },
      };
      if (this.fromOfferUuid) {
        link.query = { fromOfferUuid: this.fromOfferUuid };
      }
      return link;
    },
  },
  methods: {
    cutString(text) {
      return cutString(text, 250);
    },
    jobIcon() {
      if (!this.offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[this.offer.transformed_area] ? iconDict[this.offer.transformed_area]
        : 'fal fa-question-circle';
    },
  },
};
</script>
