<template>
  <div class="">
    <v-btn
      icon
      :disabled="disableButton"
      @click="toggleFavouriteClicked"
    >
      <v-icon
        :color="color"
        :class="$vuetify.breakpoint.mdAndUp
          ? 'icon-size' : 'icon-size-responsive'"
      >
        {{ offer.is_favourite ? 'fas fa-bookmark' : 'far fa-bookmark' }}
      </v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ offer.is_favourite ? textAdded : textRemoved }}
      <v-btn
        color="orange"
        flat
        @click="undoSnackbarClicked"
      >
        Deshacer
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import { JwtIsExpired } from '@/utils/jwt';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'FavouriteButton',
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    offer: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#595959',
    },
  },
  data() {
    return {
      loading: false,
      snackbar: false,
      y: 'bottom',
      x: 'right',
      mode: 'multi-line',
      timeout: 6000,
      textAdded: 'Se ha agregado la oferta de favoritos',
      textRemoved: 'Se ha eliminado la oferta de favoritos',
    };
  },
  computed: {
    disableButton() {
      return this.loading || this.disabled || !this.userIsLoggedIn;
    },
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
    toggleFavouriteClicked() {
      this.gaEvent('Added to Favourites', 'offers', this.offer.uuid, 1);
      this.toggleFavourite();
      this.showUndoSnackbar();
    },
    toggleFavourite() {
      this.loading = true;
      const endpoint = this.$store.state.endpoints.offers.candidates.favourite(this.offer.uuid);
      axios.post(endpoint, {}, this.jwtConfig)
        .then(response => {
          this.$emit('setFavourite', response.data.is_favourite);
          this.loading = false;
        }).catch(error => {
          console.error(error);
          this.loading = false;
        });
    },
    showUndoSnackbar() {
      this.snackbar = true;
    },
    undoSnackbarClicked() {
      this.snackbar = false;
      this.toggleFavourite();
    },
  },
};
</script>

<style scoped>
.icon-size {
  height: 18px;
  width: 14px;
}
.icon-size-responsive {
  height: 9.75px;
  width: 7.58px;
}
</style>
