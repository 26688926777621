/**
 * Given a string and a max length, cuts the text at that length.
 * */
const cutString = (text, maxLength) => {
  let cut = text.substring(0, maxLength);
  if (cut.length < text.length) {
    cut += ' ...';
  }
  return cut;
};

export default cutString;
