<script>
import Parser from 'rss-parser';

export default {
  name: 'RSSFeedMixin',
  data() {
    return {
      loading_rss: false,
      feed: [],
    };
  },
  methods: {
    /**
     * @return {string}
     */
    RSSFeedLink(country) {
      if (country === 'PE') {
        return 'https://mandomedio.com/peru/feed/';
      }
      if (country === 'CO') {
        return 'https://mandomedio.com/colombia/feed/';
      }
      // default to chilean (CL) link
      return 'https://mandomedio.com/feed/';
    },
    loadRSSFeed(country) {
      this.loading_rss = true;
      const parser = new Parser();
      parser.parseURL(this.RSSFeedLink(country), (err, feed) => {
        this.loading_rss = false;
        this.feed = feed.items;
      });
    },
  },
};
</script>
