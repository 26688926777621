<template>
  <v-autocomplete
    v-model="pickerValue"
    :disabled="loading || regions.length < 1 || !editable"
    :loading="loading"
    :items="regions"
    item-value="uuid"
    item-text="name"
    v-bind="$attrs"
    :clearable="clearable"
    label="Región"
    no-data-text="Escribe para buscar..."
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegionPickerV2',
  props: {
    country: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerValue: '',
      regions: [],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadRegions();
      this.pickerValue = this.value;
    });
  },
  methods: {
    loadRegions() {
      this.loading = true;
      const endpoint = this.$store.state.endpoints.offers.public.regions;
      axios.get(endpoint, { params: { country: this.country } })
        .then(response => {
          this.regions = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
