<script>
export default {
  name: 'SocialNetworksMixin',
  data() {
    return {
      networks: [
        {
          name: 'email',
          color: '#333333',
          icon: 'mdi-email',
        },
        {
          name: 'whatsapp',
          color: '#25d366',
          icon: 'mdi-whatsapp',
        },
        {
          name: 'twitter',
          color: '#008cc0',
          icon: 'mdi-twitter',
        },
        {
          name: 'facebook',
          color: '#135fc2',
          icon: 'mdi-facebook',
        },
      ],
    };
  },
};
</script>
