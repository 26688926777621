<template>
  <v-layout>
    <v-container grid-list-lg>
      <v-layout
        v-if="offers.length > 0"
        mt-4
        mb-4
      >
        <h1
          v-if="$vuetify.breakpoint.mdAndUp"
          class="blue-grey--text text--darken-1"
        >
          Otras ofertas de empleos similares
        </h1>
        <h2
          v-else
          class="blue-grey--text text--darken-1 font-weight-black ml-2"
        >
          Otras ofertas de empleos similares
        </h2>
      </v-layout>
      <v-layout
        v-if="offers.length > 0"
        row
        wrap
      >
        <v-flex
          v-for="o in offers"
          :key="o.uuid"
          xs12
        >
          <PublicOfferCard
            :offer="o"
            :show-perks="true"
            :disable-favourite="true"
            :from-offer-uuid="offer.uuid"
            @setFavourite="isFavourite => o.is_favourite = isFavourite"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>

<script>

import PublicOfferCard from '@/components/offers/PublicOfferCard.vue';

export default {
  name: 'PublicOfferRelatedOffer',
  components: {
    PublicOfferCard,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    offers: {
      type: Object,
      required: true,
    },
    disableFavourite: {
      type: Boolean,
      default: false,
    },
  },
};

</script>
