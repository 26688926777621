<template>
  <div>
    <v-container
      fluid
      grid-list-lg
      class="p-0 m-0"
    >
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex
          lg12
          class="bg-f5"
        >
          <!-- Hide Oreange Line -->
        </v-flex>
      </v-layout>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex
          lg12
          class="background-orange-gradient"
        >
          <v-layout
            class="layout-shape"
          >
            <div class="custom-shape-divider-top-1633707516">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  :d="pathD"
                  class="shape-fill"
                />
              </svg>
            </div>
          </v-layout>
          <!-- Logo -->
          <v-layout
            align-center
            justify-center
            row
          >
            <v-img
              class="footer-logo"
              :src="require('@/assets/Logo MM Alta vertical-blanco.png')"
              max-height="130"
              contain
            />
          </v-layout>
          <v-container>
            <v-layout
              v-if="$vuetify.breakpoint.mdAndUp"
              row
              wrap
              class="margin-y"
              justify-center
              style=""
            >
              <v-flex
                v-if="false"
                xs12
                sm3
                md3
              >
                <div class="ml-3 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Empleos por país
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Chile
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Peru
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Colombia
                    </a>
                  </h3>
                </div>
              </v-flex>
              <v-flex
                v-if="true"
                xs12
                sm3
                md3
              >
                <div class="ml-3 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Empleos por nivel de cargo
                  </h3>
                  <h3
                    v-for="(item, index) in cargos"
                    :key="`areas-${index}`"
                  >
                    <router-link
                      :to="getSearchLink(item.value)"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      {{ item.text }}
                    </router-link>
                  </h3>
                </div>
              </v-flex>
              <v-flex
                v-if="false"
                xs12
                sm3
                md3
              >
                <div class="ml-3 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Empleos por tags
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      SEO
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      SEM
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      CRM
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Part-time
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Full-time
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      English
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Java
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Python
                    </a>
                  </h3>
                </div>
              </v-flex>
              <v-flex
                v-if="false"
                xs12
                sm3
                md3
              >
                <div class="ml-3 mb-5 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Empleos por ciudad
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Santiago
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Antofagasta
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Concepción
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Lima
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Arequipa
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Bogotá
                    </a>
                  </h3>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
              >
                <div class="ml-3 mb-5 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Empleos por área
                  </h3>
                  <h3
                    v-for="(item, index) in areas"
                    :key="`areas-${index}`"
                  >
                    <RouterLink
                      :to="{name: 'candidate:offers',query: { keyword: item.name }}"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      {{ item.name }}
                    </RouterLink>
                  </h3>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm3
                md3
              >
                <div class="ml-3 mt-5">
                  <h3 class="font-weight-bold mb-3 white--text">
                    Conócenos
                  </h3>
                  <h3>
                    <a
                      href="https://mandomedio.com/blog/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Blog Mandomedio
                    </a>
                  </h3>
                  <h3>
                    <a
                      href="https://mandomedio.com/nuestra-empresa/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Nosotros
                    </a>
                  </h3>
                  <h3 v-if="false">
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Eventos
                    </a>
                  </h3>
                  <h3 v-if="false">
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Precios
                    </a>
                  </h3>
                  <h3 v-if="false">
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Ayuda
                    </a>
                  </h3>
                  <h3 v-if="false">
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      class="font-weight-thin white--text pt-0"
                      style="text-decoration: None"
                    >
                      Políticas de privacidad y
                      <br>
                      protección de datos personales
                    </a>
                  </h3>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-layout
            v-if="!$vuetify.breakpoint.mdAndUp"
            row
            wrap
          >
            <v-flex xs12>
              <div class="mb-2 mt-2 ml-1">
                <v-expansion-panel
                  v-if="false"
                  class="elevation-0"
                >
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Empleos por país
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Chile
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Peru
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Colombia
                          </a>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="true"
                  class="elevation-0"
                >
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Empleos por nivel de cargo
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3
                          v-for="(item, index) in cargos"
                          :key="`areas-${index}`"
                        >
                          <router-link
                            :to="getSearchLink(item.value)"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            {{ item.text }}
                          </router-link>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="false"
                  class="elevation-0"
                >
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Empleos por tags
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Html
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            SEO
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            SEM
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            CRM
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Part-time
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Full-time
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            English
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Java
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Python
                          </a>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="false"
                  class="elevation-0"
                >
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Empleos por ciudad
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Santiago
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Antofagasta
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Concepción
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Lima
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Arequipa
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Bogotá
                          </a>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  class="elevation-0"
                >
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Empleos por área
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3
                          v-for="(item, index) in areas"
                          :key="`areas-${index}`"
                        >
                          <RouterLink
                            :to="{name: 'candidate:offers',query: { keyword: item.name }}"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            {{ item.name }}
                          </RouterLink>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="elevation-0">
                  <v-expansion-panel-content style="background: #00000000">
                    <template v-slot:actions>
                      <v-icon
                        color="white"
                        large
                      >
                        $vuetify.icons.expand
                      </v-icon>
                    </template>
                    <template v-slot:header>
                      <h3 class="font-weight-bold white--text">
                        Conócenos
                      </h3>
                    </template>
                    <v-card style="background: #00000000">
                      <v-card-text>
                        <h3>
                          <a
                            href="https://mandomedio.com/blog/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Blog Mandomedio
                          </a>
                        </h3>
                        <h3>
                          <a
                            href="https://mandomedio.com/nuestra-empresa/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Nosotros
                          </a>
                        </h3>
                        <h3 v-if="false">
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Eventos
                          </a>
                        </h3>
                        <h3 v-if="false">
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Precios
                          </a>
                        </h3>
                        <h3 v-if="false">
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Ayuda
                          </a>
                        </h3>
                        <h3 v-if="false">
                          <a
                            href="https://www.google.com/"
                            target="_blank"
                            class="font-weight-thin white--text ml-2"
                            style="text-decoration: None"
                          >
                            Políticas de privacidad y
                            <br>
                            <p class="ml-2">
                              protección de datos personales
                            </p>
                          </a>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </div>
            </v-flex>
          </v-layout>
          <!-- Icons md and up-->
          <v-layout
            v-if="$vuetify.breakpoint.mdAndUp"
            row
            justify-center
            ml-3
          >
            <a
              href="https://www.linkedin.com/company/mandomedio/"
              target="_blank"
              style="text-decoration: None"
              class="white--text social-anchor"
            >
              <v-flex>
                <v-layout>
                  <v-img
                    class="social-icon"
                    :src="require('@/assets/ic_li.svg')"
                  />
                  <span
                    class="social-link"
                  >
                    LinkedIn
                  </span>
                </v-layout>
              </v-flex>
            </a>
            <a
              href="https://www.facebook.com/MandomedioLtm/"
              target="_blank"
              style="text-decoration: None"
              class="white--text social-anchor"
            >
              <v-flex>
                <v-layout>
                  <v-img
                    class="social-icon"
                    :src="require('@/assets/ic_fb.svg')"
                  />
                  <span
                    class="social-link"
                  >
                    Facebook
                  </span>
                </v-layout>
              </v-flex>
            </a>
            <a
              href="https://www.instagram.com/mandomedio_com/"
              target="_blank"
              style="text-decoration: None"
              class="white--text social-anchor"
            >
              <v-flex>
                <v-layout>
                  <v-img
                    class="social-icon"
                    :src="require('@/assets/ic_ig.svg')"
                  />
                  <span
                    class="social-link"
                  >
                    Instagram
                  </span>
                </v-layout>
              </v-flex>
            </a>
            <a
              href="https://twitter.com/Mandomedio_com"
              target="_blank"
              style="text-decoration: None"
              class="white--text social-anchor"
            >
              <v-flex>
                <v-layout>
                  <v-img
                    class="social-icon"
                    :src="require('@/assets/ic_tw.svg')"
                  />
                  <span
                    class="social-link"
                  >
                    Twitter
                  </span>
                </v-layout>
              </v-flex>
            </a>
          </v-layout>
          <!-- Icons -->
          <v-layout
            v-else
            row
            justify-center
            ml-2
          >
            <a
              href="https://www.linkedin.com/company/mandomedio/"
              target="_blank"
              style="text-decoration: None"
            >
              <v-img
                class="social-icon-responsive"
                :src="require('@/assets/ic_li.svg')"
              />
            </a>
            <a
              href="https://www.facebook.com/MandomedioLtm/"
              target="_blank"
              style="text-decoration: None"
            >
              <v-img
                class="social-icon-responsive"
                :src="require('@/assets/ic_fb.svg')"
              />
            </a>
            <a
              href="https://www.instagram.com/mandomedio_com/"
              target="_blank"
              style="text-decoration: None"
            >
              <v-img
                class="social-icon-responsive"
                :src="require('@/assets/ic_ig.svg')"
              />
            </a>
            <a
              href="https://twitter.com/Mandomedio_com"
              target="_blank"
              style="text-decoration: None"
            >
              <v-img
                class="social-icon-responsive"
                :src="require('@/assets/ic_tw.svg')"
              />
            </a>
          </v-layout>
          <!-- C Mandomedio -->
          <v-layout
            justify-center
            mt-2
          >
            <template>
              <div class="margin-t-28">
                <p>
                  <a
                    href="https://mandomedio.com"
                    target="_blank"
                    :class="$vuetify.breakpoint.mdAndUp ?
                      'mandomedio-c' : 'mandomedio-c-responsive'"
                  >
                    Mandomedio {{ new Date().getFullYear() }} ®
                  </a>
                </p>
              </div>
            </template>
          </v-layout>
          <!-- End Footer -->
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Footer',
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      pathD: 'M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z',
      areas: [
        {
          name: 'Comercial',
        },
        {
          name: 'Administración',
        },
        {
          name: 'Ventas',
        },
        {
          name: 'Recursos Humanos',
        },
        {
          name: 'Contabilidad',
        },
        {
          name: 'Tecnologia / Sistemas',
        },
        {
          name: 'Tecnologías de la Información',
        },
        {
          name: 'Marketing',
        },
        {
          name: 'Operaciones',
        },
        {
          name: 'Construcción',
        },
      ],
      cargos: [],
    };
  },
  mounted() {
    this.loadPositionTiersName();
  },
  methods: {
    getSearchLink(positionTier) {
      return {
        name: 'candidate:offers',
        query: {
          position_tier: positionTier,
        },
      };
    },
    loadPositionTiersName() {
      axios.get(this.$store.state.endpoints.offers.public.positionTiers)
        .then(resp => {
          this.cargos = resp.data.map(element => ({
            text: element.text,
            value: element.value,
          }));
        });
    },
  },
};
</script>

<style scoped>
.p-0{
  padding: 0px;
}
.m-0{
  margin: 0px;
}
.bg-f5{
  background-color: #f5f5f5;
}
.text-center{
  text-align: center;
}
.footer-logo{
  height: 99px;
  width: 190.3px;
}
.social-anchor{
  margin-left: 64px;
}
.social-link{
  height: 32px;
  width: 77px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-left: 16px;
}
.social-icon{
  height: 30px;
  width: 30px;
  color: #FFFFFF;
}
.social-icon-responsive{
  height: 20px;
  width: 20px;
  color: #FFFFFF;
  margin-left: 12px;
  margin-right: 12px;
}
.background-orange-gradient{
  background: rgb(229,102,46);
  background: linear-gradient(0deg, rgba(229,102,46,1) 0%, rgba(229,145,61,1) 100%);
}
.gradient-orange-dark{
  background-color: #E5662E;
}
.gradient-orange-light{
  background-color: #E5913D;
}
.mandomedio-c{
  height: 28px;
  width: 138px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 28px;
  text-decoration: None;
}
.mandomedio-c-responsive{
  height: 28px;
  width: 104px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 28px;
  text-decoration: None;
}
.margin-t-28{
  margin-top: 28px;
}
.custom-shape-divider-top-1633707516 {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.custom-shape-divider-top-1633707516 svg {
  position: relative;
  display: block;
  width: 285%;
  height: 300px;
}
.custom-shape-divider-top-1633707516 .shape-fill {
  fill: #F5F5F5;
}
.layout-shape{
  position: relative;
  display: block;
  height: 200px;
}
.margin-y{
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
}
</style>
