<script>
export default {
  name: 'LogoutMethodMixin',
  methods: {
    logout() {
      this.$store.commit('logout');
      this.$router.push({ name: 'candidate:landing' });
    },
  },
};
</script>
