<template>
  <v-form>
    <v-card class="card-login">
      <v-layout
        justify-end
      >
        <v-btn
          icon
          style="position: absolute"
          @click="closeModal()"
        >
          <v-icon
            icon
          >
            fal fa-times
          </v-icon>
        </v-btn>
      </v-layout>
      <v-container>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
            >
              <p
                class="text-welcome"
              >
                ¡Te damos la bienvenida!
              </p>
            </v-flex>
            <v-flex
              xs12
              mb-2
            >
              <v-alert
                v-model="errorchip"
                outline
                class="alert-error"
              >
                <p
                  class="text-alert"
                  :style="$vuetify.breakpoint.smAndUp
                    ? 'font-size: 16px' : 'font-size: 12px'"
                >
                  {{ chipmsg }}
                </p>
              </v-alert>
            </v-flex>
            <v-flex
              xs12
              :style=" fnameErrors.length > 0 ? 'margin-bottom: 13px' : ''"
            >
              <v-text-field
                v-model="firstname"
                type="text"
                name="firstname"
                autocomplete="given-name"
                label="Nombre"
                full-width
                class="inputs"
                :error-messages="fnameErrors"
              />
            </v-flex>
            <v-flex
              xs12
              mt-3
              :style=" lnameErrors.length > 0 ? 'margin-bottom: 13px' : ''"
            >
              <v-text-field
                v-model="lastname"
                type="text"
                name="lastname"
                autocomplete="family-name"
                label="Apellido"
                full-width
                class="inputs"
                :error-messages="lnameErrors"
              />
            </v-flex>
            <v-flex
              xs12
              mt-3
              :style=" emailErrors.length > 0 ? 'margin-bottom: 13px' : ''"
            >
              <v-text-field
                v-model="email"
                type="email"
                name="email"
                autocomplete="email"
                label="Correo electrónico"
                full-width
                class="inputs"
                :error-messages="emailErrors"
              />
            </v-flex>
            <v-flex
              xs12
              mt-3
              :class="marginPassError ? 'mb-4' : ''"
              :style=" passwordErrors.length > 0 ? 'margin-bottom: 14px' : ''"
            >
              <v-text-field
                v-model="password"
                name="password"
                label="Contraseña"
                full-width
                class="inputs"
                :error-messages="passwordErrors"
                :append-icon="showPass1 ? 'fal fa-eye' : 'fal fa-eye-slash'"
                :type="showPass1 ? 'text' : 'password'"
                @click:append="showPass1 = !showPass1"
              />
            </v-flex>
            <v-flex
              xs12
              :class="marginPassError ? 'mt-4' : 'mt-3'"
            >
              <v-text-field
                v-model="passwordConf"
                name="passwordConf"
                label="Confirmar contraseña"
                full-width
                outline
                :hide-details="passwordConfErrors.length === 0"
                :error-messages="passwordConfErrors"
                :append-icon="showPass ? 'fal fa-eye' : 'fal fa-eye-slash'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              />
            </v-flex>

            <v-layout
              row
              wrap
            >
              <v-layout>
                <v-checkbox
                  v-model="is_terms_and_privacy"
                  color="info2"
                  height="0"
                />
                <v-flex
                  xs12
                >
                  <p class="terms-privacy">
                    Acepto las
                    <!--<router-link
                      :to="{name: 'privacy-policy'}"
                      target="_blank"
                      style="text-decoration: none; color: #154360"
                    >
                      <b style="color: #30B0BF">
                        Condiciones de uso
                      </b>
                    </router-link>y las
                    -->
                    <router-link
                      :to="{name: 'privacy-policy'}"
                      target="_blank"
                      style="text-decoration: none; color: #154360"
                    >
                      <b style="color: #30B0BF">
                        Políticas de privacidad
                      </b>
                    </router-link>
                    de Mandomedio.
                  </p>
                </v-flex>
              </v-layout>
              <v-flex
                v-for="(item, index) in is_terms_and_privacy_errors"
                :key="index"
              >
                <p
                  class="ml-3 terms-privacy-error"
                >
                  {{ item }}
                </p>
              </v-flex>
            </v-layout>
            <v-flex
              xs12
            >
              <v-btn
                style="border-radius: 5px"
                block
                color="info2"
                type="submit"
                @click.prevent="authenticate()"
              >
                <p class="button-text">
                  Regístrate
                </p>
              </v-btn>
            </v-flex>
            <v-flex mt-2>
              <v-layout>
                <v-btn
                  xs12
                  block
                  outline
                  class="button-goglee"
                  @click.prevent="googleLogin()"
                >
                  <img
                    src="@/assets/download.svg"
                    alt="logo"
                    style="margin-right: 6px"
                  >
                  <p class="text-google">
                    Continuar con Google
                  </p>
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex
              style="margin-top: 50px"
            >
              <v-layout>
                <p class="text-not-account">
                  ¿Tienes  una cuenta?
                </p>
                <p
                  class="text-signup"
                  @click="changeModal()"
                >
                  Iniciar sesión
                </p>
              </v-layout>
            </v-flex>
            <v-flex mt-4>
              <p class="text-notification-google">
                Mandomedio te enviará ofertas laborales, tips y consejos a
                tu correo electrónico. Puedes desactivar estas notificaciones
                en el panel de cofiguraciones de tu cuenta.
              </p>
            </v-flex>
            <v-flex
              mt-2
              xs12
            >
              <v-divider />
            </v-flex>
            <v-flex
              xs12
              mt-3
            >
              <v-layout
                justify-center
              >
                <img
                  src="@/assets/Logo-Mandomedio-PDE.png"
                  alt="logo-mandomedio"
                  width="172px"
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text v-if="false">
          <v-layout
            column
            px-4
          >
            <v-flex
              xs12
              px-3
              ml-3
            >
              <p>
                Al crear mi cuenta, acepto las condiciones de uso y las
                <router-link
                  :to="{name: 'privacy-policy'}"
                  target="_blank"
                  style="text-decoration: none; color: #154360"
                >
                  <b>
                    Políticas de privacidad
                  </b>
                </router-link>
                de Mandomedio.
              </p>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      style="margin: 0px"
      persistent
    >
      <v-card class="modal-signup-complete">
        <v-layout
          justify-end
          mb-2
        >
          <v-btn
            icon
            style="position: absolute"
            @click="signup_modal = false"
          >
            <v-icon
              icon
            >
              fal fa-times
            </v-icon>
          </v-btn>
        </v-layout>
        <v-layout
          justify-center
        >
          <img
            style="margin-bottom: 50px;margin-top: 90px"
            src="@/assets/email.svg"
            alt="logo"
          >
        </v-layout>
        <v-layout
          justify-center
        >
          <p class="title-modal-signup">
            Revisa tu correo
          </p>
        </v-layout>
        <v-layout
          justify-center
        >
          <p class="text-modal-signup-complete">
            Te hemos enviado el link de confirmación a
            {{ email }} para que puedas iniciar sesión.
          </p>
        </v-layout>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CandidateSignup',
  data() {
    return {
      errorchip: false,
      chipmsg: '',
      submitStatus: 'PENDING',
      password: '',
      passwordConf: '',
      firstname: '',
      lastname: '',
      email: '',
      menu2: false,
      date: '',
      country: 'CL',
      countryLive: 'CL',
      studies: 0,
      idnumber: '',
      loading: false,
      showPass: false,
      showPass1: false,
      respErrors: null,
      signup_modal: false,
      fnameErrors: [],
      lnameErrors: [],
      emailErrors: [],
      passwordErrors: [],
      passwordConfErrors: [],
      is_terms_and_privacy_errors: [],
      marginPassError: false,
      is_terms_and_privacy: false,
    };
  },
  computed: {
    nationalIdHint() {
      if (this.countryLive === 'CL') {
        return 'Para Chile, debe seguir este formato: 12345678-9';
      } if (this.countryLive === 'PE') {
        return 'Para Perú, debe seguir este formato: 12345678';
      }
      return '';
    },
  },
  watch: {
    menu2(val) {
      if (val) {
        setTimeout(() => { this.$refs.picker.activePicker = 'YEAR'; });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$ga.event('registro', 'quieroRegistrarme', 'quieroRegistrarme', 1);
    });
  },

  methods: {
    googleLogin() {
      axios.post(this.$store.state.endpoints.google.getUrl)
        .then(resp => {
          window.location = resp.data.url;
        });
    },
    closeModal() {
      this.$emit('closeModal', '');
      this.fnameErrors = [];
      this.lnameErrors = [];
      this.emailErrors = [];
      this.passwordErrors = [];
      this.passwordConfErrors = [];
      this.is_terms_and_privacy_errors = [];
      this.errorchip = false;
      this.marginPassError = false;
      this.password = '';
      this.passwordConf = '';
      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.is_terms_and_privacy = false;
    },
    changeModal() {
      this.$emit('changeModal', '');
      this.fnameErrors = [];
      this.lnameErrors = [];
      this.emailErrors = [];
      this.passwordErrors = [];
      this.passwordConfErrors = [];
      this.is_terms_and_privacy_errors = [];
      this.errorchip = false;
      this.marginPassError = false;
      this.password = '';
      this.passwordConf = '';
      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.is_terms_and_privacy = false;
    },
    authenticate() {
      this.respErrors = null;
      this.fnameErrors = [];
      this.lnameErrors = [];
      this.emailErrors = [];
      this.passwordErrors = [];
      this.passwordConfErrors = [];
      this.is_terms_and_privacy_errors = [];
      this.$store.commit('setLoading');
      const form = new FormData();
      form.append('password', this.password);
      form.append('password_conf', this.passwordConf);
      form.append('first_name', this.firstname);
      form.append('last_name', this.lastname);
      form.append('email', this.email);
      form.append('url', this.$route.fullPath);
      form.append('is_terms_and_privacy', this.is_terms_and_privacy);

      const payloadLogin = {
        username: this.email,
        password: this.password,
      };

      const headers = {
        'Content-Type': 'multipart/form-data; boundary = &',
      };

      axios.post(this.$store.state.endpoints.candidates.register, form, { headers })
        .then(() => {
          this.$emit('signupDone', payloadLogin);
          this.$store.commit('unsetLoading');
          this.signup_modal = true;
        })
        .catch(e => {
          this.errorchip = true;
          if (typeof e.response.data === 'string') {
            this.chipmsg = e.response.data;
            this.respErrors = { email: e.response.data };
          } else if (e.response.data.non_field_errors) {
            this.chipmsg = e.response.data.non_field_errors.pop();
          } else {
            if (e.response.data.first_name) {
              this.fnameErrors = [...e.response.data.first_name];
            }
            if (e.response.data.last_name) {
              this.lnameErrors = [...e.response.data.last_name];
            }
            if (e.response.data.email) {
              this.emailErrors = [...e.response.data.email];
            }
            if (e.response.data.password) {
              this.marginPassError = false;
              const textPass = e.response.data.password[0].split('carácter(es)')[1];
              this.passwordErrors = [...e.response.data.password];
              if (textPass !== undefined) {
                this.marginPassError = true;
              }
            }
            if (e.response.data.password_conf) {
              this.passwordConfErrors = [...e.response.data.password_conf];
            }
            if (e.response.data.is_terms_and_privacy) {
              this.is_terms_and_privacy_errors = [...e.response.data.is_terms_and_privacy];
            }
            this.chipmsg = 'Por favor verifique los datos ingresados en el formulario.';
          }
          this.$store.commit('unsetLoading');
        });
    },
  },
};
</script>
<style scoped>
.card-login {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.text-welcome {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
}
.button-goglee {
  box-sizing: border-box;
  border: 1px solid rgba(151,151,151,0.35);
  border-radius: 6px;
  margin: 0px;
}
.text-google {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
.forget-pass {
  color: #30B0BF;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.29px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 11px;
}
.terms-privacy{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.29px;
  line-height: 14px;
  margin-top: 14px;
}
.button-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
.text-separator {
  color: rgba(0, 0, 0, 0.26);
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 28px;
}
.inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
.text-not-account{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin-right: 10px;
}
.text-signup {
  color: #30B0BF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  cursor: pointer;
}
.text-notification-google {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 13px;
}
.modal-signup-complete {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.title-modal-signup {
  height: 32px;
  width: 133px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
  margin: 0px;
}
.text-modal-signup-complete {
  height: 121px;
  width: 350px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
}
.alert-error {
  height: 67px;
  border-radius: 6px;
  background-color: rgba(252,81,81,0.16);
}
.text-alert {
  color: #FC5151;
  font-family: Lato;
  letter-spacing: 0.11px;
  line-height: 19px;
  margin: 0px;
  text-align: center;
}
.terms-privacy-error{
  color: #FF5252;
  font: 12px lato;
}
</style>
