<template>
  <v-layout
    align-center
    justify-center
    column
    fill-height
    class="mt-5 mb-5"
  >
    <v-img
      :src="require('@/assets/offer-not-available.png')"
      class="image-sizes"
    />
    <p class="text-title">
      Este empleo ya no está disponible
    </p>
    <p
      v-if="false"
      class="text-body"
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit nam ullamcorper justo eget facilisis
      egestas morbi luctus laoreet orci a aliquet maecenas nisi arcu, porta sit amet ligula..
    </p>
  </v-layout>
</template>

<script>
export default {
  name: 'NotAvailable',
};
</script>

<style scoped>
  .text-title{
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 0.25px;
    line-height: 42px;
  }
  .text-body{
    max-width: 463px;
    color: rgba(0,0,0,0.6);
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    text-align: center;
  }
  .image-sizes{
    max-width:204px;
    width:204px;
    height:143px;
    max-height:143px
  }
</style>
