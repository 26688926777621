<script>
import { JwtIsExpired } from '@/utils/jwt';

export default {
  name: 'UserIsLoggedComputedMixin',
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
};
</script>
