<template>
  <v-form>
    <v-card
      class="card-login"
    >
      <v-toolbar
        v-if="loginType === 'admin'"
        dark
        color="primary"
      >
        <v-toolbar-title>Entrar</v-toolbar-title>
      </v-toolbar>
      <v-layout
        justify-end
      >
        <v-btn
          icon
          style="position: absolute"
          @click="closeLogin()"
        >
          <v-icon
            icon
          >
            fal fa-times
          </v-icon>
        </v-btn>
      </v-layout>
      <v-container>
        <v-card-text>
          <v-layout
            row
            wrap
          >
            <v-flex
              v-if="loginType !== 'admin'"
              xs12
            >
              <p
                class="text-welcome"
              >
                ¡Te damos la bienvenida!
              </p>
            </v-flex>
            <v-alert
              v-if="loginType !== 'admin'"
              v-model="errorchip"
              outline
              class="warning-error"
              color="#EF9A12"
            >
              <p
                class="text-warning-error"
                :style="$vuetify.breakpoint.smAndUp
                  ? 'font-size: 16px' : 'font-size: 12px'"
              >
                {{ chipMsg }}
              </p>
            </v-alert>
            <v-flex
              v-if="loginType !== 'admin'"
              my-3
            >
              <v-layout>
                <v-btn
                  xs12
                  block
                  outline
                  class="button-goglee"
                  @click.prevent="googleLogin()"
                >
                  <img
                    src="@/assets/download.svg"
                    alt="logo"
                    style="margin-right: 6px"
                  >
                  <p class="text-google">
                    Continuar con Google
                  </p>
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex
              v-if="loginType !== 'admin'"
              xs12
            >
              <v-layout justify-center>
                <v-divider style="position: absolute;background-color: #212121" />
                <p class="text-separator">
                  O ingresa con tu correo electrónico
                </p>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              :style=" userErrors.length > 0 ? 'margin-bottom: 13px' : ''"
            >
              <v-text-field
                ref="username"
                v-model="username"
                type="text"
                autofocus="autofocus"
                maxlength="150"
                name="login"
                label="Correo electrónico"
                full-width
                class="inputs"
                :error-messages="userErrors"
              />
            </v-flex>
            <v-flex
              mt-3
              xs12
            >
              <v-text-field
                ref="password"
                v-model="password"
                name="password"
                label="Contraseña"
                full-width
                outline
                :hide-details="passErrors.length === 0"
                :error-messages="passErrors"
                :append-icon="showPass ? 'fal fa-eye' : 'fal fa-eye-slash'"
                :type="showPass ? 'text' : 'password'"
                @click:append="showPass = !showPass"
              />
            </v-flex>
            <v-flex>
              <v-layout justify-end>
                <router-link
                  class="forget-pass"
                  target="_blank"
                  :to="{name: 'forget-password'}"
                >
                  ¿Olvidaste tu contraseña?
                </router-link>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              mt-3
            >
              <v-btn
                style="border-radius: 5px"
                block
                color="info2"
                type="submit"
                @click.prevent="authenticate"
              >
                <p class="button-text">
                  Ingresar
                </p>
              </v-btn>
            </v-flex>
            <v-flex
              v-if="loginType !== 'admin'"
              style="margin-top: 30px"
            >
              <v-layout>
                <p class="text-not-account">
                  ¿No tienes cuenta?
                </p>
                <p
                  class="text-signup"
                  @click="changeModal()"
                >
                  Regístrate
                </p>
              </v-layout>
            </v-flex>
            <v-flex
              mt-2
              xs12
            >
              <v-divider />
            </v-flex>
            <v-flex
              v-if="loginType !== 'admin'"
              mt-2
            >
              <p class="text-notification-google">
                Al crear mi cuenta, acepto las Condiciones de uso y las Políticas
                de privacidad de Mandomedio.
              </p>
            </v-flex>
            <v-flex
              xs12
              mt-3
            >
              <v-layout
                justify-center
              >
                <img
                  src="@/assets/Logo-Mandomedio-PDE.png"
                  alt="logo-mandomedio"
                  width="172px"
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <v-layout
            align-center
            justify-center
            column
            wrap
          >
            <v-flex
              v-if="false"
              xs3
            >
              <v-btn
                color="#0077B5"
                type="submit"
                align="center"
                style="color: white;"
                @click.prevent="linkedinLogin"
              >
                <v-icon left>
                  mdi-linkedin-box
                </v-icon>
                Entrar con LinkedIn
              </v-btn>
            </v-flex>
            <v-flex
              v-if="loginType === 'admin'"
              xs3
            >
              <v-btn
                align="center"
                color="#016EBF"
                style="color: white"
                @click.prevent="windowsliveLogin"
              >
                <v-icon left>
                  mdi-outlook
                </v-icon>
                Entrar con tu cuenta de Mandomedio
              </v-btn>
            </v-flex>
          </v-layout>
          <v-chip
            v-if="loginType === 'admin'"
            v-model="errorchip"
            close
            color="red"
            text-color="white"
          >
            {{ chipMsg }}
          </v-chip>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      persistent
    >
      <CandidateSignup
        @signupDone="finishSignup"
        @closeModal="signup_modal = false"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import axios from 'axios';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';
import { parseJwt } from '@/utils/jwt';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'LoginCard',
  components: {
    CandidateSignup,
  },
  mixins: [JWTConfigMixin],
  props: {
    loginType: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {
      username: '',
      password: '',
      signup_modal: false,
      chipMsg: '',
      errorchip: false,
      userErrors: [],
      passErrors: [],
      showPass: false,
    };
  },
  methods: {
    changeModal() {
      this.$emit('changeModal', '');
      this.userErrors = [];
      this.passErrors = [];
      this.chipMsg = '';
      this.username = '';
      this.password = '';
      this.errorchip = false;
    },
    closeLogin() {
      this.$emit('closeLogin', '');
      this.userErrors = [];
      this.passErrors = [];
      this.chipMsg = '';
      this.username = '';
      this.password = '';
      this.errorchip = false;
    },
    linkedinLogin() {
      axios.post(this.$store.state.endpoints.linkedin.getUrl)
        .then(resp => {
          window.location = resp.data.url;
        });
    },
    windowsliveLogin() {
      axios.post(this.$store.state.endpoints.windowslive.getUrl)
        .then(resp => {
          window.location = resp.data;
        });
    },
    googleLogin() {
      axios.post(this.$store.state.endpoints.google.getUrl)
        .then(resp => {
          window.location = resp.data.url;
        });
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.username = payload.username;
      this.password = payload.password;
      this.$emit('signupDone', '');
      this.authenticate();
    },
    authenticate() {
      this.$store.commit('setLoading');
      this.errorchip = false;
      this.passErrors = [];
      this.userErrors = [];

      const payload = {
        username: this.username,
        password: this.password,
      };

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          if (error.response.data.non_field_errors) {
            this.errorchip = true;
            this.chipMsg = error.response.data.non_field_errors.pop();
          } else {
            if (error.response.data.username) {
              this.userErrors = [...error.response.data.username];
            }
            if (error.response.data.password) {
              this.passErrors = [...error.response.data.password];
            }
          }
        });
    },
  },
};
</script>
<style scoped>
.card-login {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.text-welcome {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
}
.button-goglee {
  box-sizing: border-box;
  border: 1px solid rgba(151,151,151,0.35);
  border-radius: 6px;
  margin: 0px;
}
.text-google {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
.inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
.forget-pass {
  color: #30B0BF;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.29px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 11px;
}
.remember-me {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.29px;
  line-height: 26px;
  margin-top: 18px;
}
.button-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
.text-separator{
  color: rgba(0,0,0,0.26);
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 28px;
}
.text-not-account{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin-right: 10px;
}
.text-signup {
  color: #30B0BF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  cursor: pointer;
}
.text-notification-google {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 13px;
}
.warning-error {
  height: 67px;
  border-radius: 6px;
  background-color: rgba(239,154,18,0.13);
}
.text-warning-error {
  color: #EF9A12;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 19px;
  text-align: center;
  margin: 0px;
}
</style>
