<template>
  <div class="white">
    <v-container
      v-if="$vuetify.breakpoint.mdAndUp"
      fluid
      :style="{
        backgroundSize: '100%',
        backgroundImage: 'url(' + require('@/assets/Fondo-blanco-escritorio.png') + ')'
      }"
      class="white"
    >
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <div class="grey--text darken-1 mb-2 ml-4">
            <v-btn
              flat
              icon
              color="grey darken-1 lighten-2"
              :to="{name: 'candidate:offers'}"
            >
              <v-icon>keyboard_backspace</v-icon>
            </v-btn>
            <v-list-title :to="{name: 'candidate:offers'}">
              Volver a empleos
            </v-list-title>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-card-text class="ml-4">
            <h1
              style="color: #ff6625; font-size: 50px;"
              class="font-weight-bold"
            >
              ¡Felicitaciones!
            </h1>
            <h2
              style="color: #ff6625; font-size: 30px;"
              class="font-weight-bold ml-1"
            >
              Ya estas postulando a este empleo:
            </h2>
          </v-card-text>
        </v-flex>
        <v-flex xs12>
          <v-card-text class="ml-4">
            <h2
              class="blue-grey--text text--darken-1"
              style="font-size: 30px;"
            >
              {{ offer.computed_title }}
            </h2>
            <h3
              class="blue-grey--text text--darken-1"
              style="font-size: 20px;"
            >
              {{ offer.region_name }},
              {{ offer.city_name }}
              <img
                :src="offer.country_flag_url"
                class="ml-1"
              >
            </h3>
            <h3
              class="blue-grey--text text--darken-1"
              style="font-size: 20px;"
            >
              {{ offer.area_name }}
            </h3>
          </v-card-text>
        </v-flex>
        <v-flex sx12>
          <v-btn
            class="ml-5 mt-2"
            medium
            dark
            color="cyan darken-1"
            @click="reviewEmployment"
          >
            Revisar empleo
          </v-btn>
        </v-flex>
        <v-flex
          xs12
          pr-5
          mr-5
        >
          <h2
            class="blue-grey--text text--darken-1 mt-5"
            style="font-size: 27px;"
          >
            <v-icon large>
              mdi-email-outline
            </v-icon>
            ¡Pronto nos pondremos en contacto!
          </h2>
          <p
            class="blue-grey--text text--darken-1 ml-5 mt-3"
            style="font-size: 20px;"
          >
            Te enviaremos un mensaje para contarte del empleo y los próximos pasos a seguir.<br>
            Revisa tu correo y las notificaciones en la plataforma.
          </p>
        </v-flex>
        <v-layout v-if="$store.state.profile_percentage !== 100">
          <v-flex
            xs6
            pr-5
            mr-5
          >
            <h2
              class="blue-grey--text text--darken-1 mt-5"
              style="font-size: 27px;"
            >
              <v-icon large>
                mdi-email-outline
              </v-icon>
              ¡Completa tu perfil!
            </h2>
            <p
              class="blue-grey--text text--darken-1 ml-5 mt-3"
              style="font-size: 20px;"
            >
              Cuentale a la empresa quien eres como profesional y ten más oportunidades de
              conseguir el empleo
            </p>
            <v-flex sx12>
              <v-btn
                class="ml-5"
                :to="{name: 'candidate:profile:detail'}"
                medium
                dark
                color="cyan darken-1"
              >
                Completar
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex
            xs6
            class="mt-5"
          >
            <div class="mt-1">
              <v-progress-circular
                :size="120"
                :width="15"
                :value="$store.state.profile_percentage ? $store.state.profile_percentage : 0"
                color="cyan darken-1"
              >
                <div>
                  <h2 style="font-size: 27px;">
                    {{ roundProfilePercentage ? roundProfilePercentage : '-' }}
                    <span>%</span>
                  </h2>
                </div>
              </v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-container>
    <div
      v-else
      :style="{
        backgroundSize: '100%',
        backgroundImage: 'url(' + require('@/assets/Fondo-blanco-movil.png') + ')'
      }"
      class="white"
    >
      <v-layout
        row
        wrap
      >
        <v-flex xs12>
          <div class="grey--text darken-1 mb-2 ml-2">
            <v-btn
              flat
              icon
              color="grey darken-1 lighten-2"
              :to="{name: 'candidate:offers'}"
            >
              <v-icon>keyboard_backspace</v-icon>
            </v-btn>
            <v-list-title :to="{name: 'candidate:offers'}">
              Volver a empleos
            </v-list-title>
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="ml-3 mb-4">
            <h1
              style="color: #ff6625; font-size: 40px;"
              class="font-weight-bold mr"
            >
              ¡Felicitaciones!
            </h1>
            <h2
              style="color: #ff6625; font-size: 25px;"
              class="font-weight-bold"
            >
              Ya estas postulando a este empleo:
            </h2>
          </div>
        </v-flex>
        <v-flex xs12>
          <div class="ml-3">
            <h2
              class="blue-grey--text text--darken-1"
              style="font-size: 27px;"
            >
              {{ offer.computed_title }}
            </h2>
            <h3
              class="blue-grey--text text--darken-1"
              style="font-size: 20px;"
            >
              {{ offer.region_name }},
              {{ offer.city_name }}
              <img
                :src="offer.country_flag_url"
                class=""
              >
            </h3>
            <h3
              class="blue-grey--text text--darken-1"
              style="font-size: 20px;"
            >
              {{ offer.area_name }}
            </h3>
          </div>
        </v-flex>
        <v-flex sx12>
          <v-layout justify-center>
            <v-btn
              class="mt-4"
              medium
              dark
              color="cyan darken-1"
              @click="reviewEmployment"
            >
              Revisar empleo
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <h2
            class="blue-grey--text text--darken-1 mt-5 ml-3"
            style="font-size: 20px;"
          >
            ¡Pronto nos pondremos en contacto!
          </h2>
          <p
            class="blue-grey--text text--darken-1 mt-3 ml-3 mr-3"
            style="font-size: 15px; text-align: justify"
          >
            Te enviaremos un mensaje para contarte del empleo y los próximos pasos a seguir.
            Revisa tu correo y las notificaciones en la plataforma.
          </p>
        </v-flex>
        <v-flex xs12>
          <h2
            class="blue-grey--text text--darken-1 mt-4 ml-3"
            style="font-size: 20px;"
          >
            ¡Completa tu perfil!
          </h2>
          <p
            class="blue-grey--text text--darken-1 mt-3 ml-3 mr-3"
            style="font-size: 15px; text-align: justify;"
          >
            Cuentale a la empresa quien eres como profesional y ten más oportunidades de
            conseguir el empleo
          </p>
          <v-flex
            xs12
            class="mt-3 mb-3"
          >
            <v-layout justify-center>
              <v-btn
                class="mr-4 my-5"
                :to="{name: 'candidate:profile:detail'}"
                medium
                dark
                color="cyan darken-1"
              >
                Completar
              </v-btn>
              <v-progress-circular
                :size="120"
                :width="15"
                :value="$store.state.profile_percentage ? $store.state.profile_percentage : 0"
                color="cyan darken-1"
              >
                <div>
                  <h2 style="font-size: 27px;">
                    {{ $store.state.profile_percentage ? $store.state.profile_percentage : '-' }}
                    <span>%</span>
                  </h2>
                </div>
              </v-progress-circular>
            </v-layout>
          </v-flex>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationConfirmed',
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profile_percentage: this.$store.state.profile_percentage,
    };
  },
  computed: {
    roundProfilePercentage() {
      return Math.floor(this.profile_percentage);
    },
  },
  methods: {
    reviewEmployment() {
      this.$emit('changeOfferState');
    },
  },
};
</script>

<style>
  .social_row {
    text-align: center;
  }
</style>
