<script>
import axios from 'axios';
import { parseJwt } from '@/utils/jwt';

export default {
  name: 'AuthenticatePayloadMethodMixin',
  methods: {
    authenticate(payload) {
      this.$store.commit('setLoading');

      axios.post(this.$store.state.endpoints.obtainJWT, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$emit('loginSuccess', '');
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          if (error.response.data.non_field_errors) {
            // this.errorchip = true;
            // this.chipMsg = error.response.data.non_field_errors.pop();
          } else {
            if (error.response.data.username) {
              // this.userErrors = [...error.response.data.username];
            }
            if (error.response.data.password) {
              // this.passErrors = [...error.response.data.password];
            }
          }
        });
    },
  },
};
</script>
