<template>
  <v-flex xs12>
    <script
      v-if="jsonLd"
      type="application/ld+json"
    >
      {{ jsonLd }}
    </script>
    <Search
      :filters="filters"
      @loadOffers="searchOffers()"
    />
    <div v-if="offer_state === 'ACTIVE'">
      <DetailBarOffer
        :offer="offer"
        @setFavourite="isFavourite => offer.is_favourite = isFavourite"
        @updatedOffer="(updatedOffer) => offer = updatedOffer"
      />
      <PublicOfferBody
        :offer="offer"
        @updatedOffer="(updatedOffer) => offer = updatedOffer"
      />
    </div>
    <div v-else-if="offer_state === 'CONFIRMED'">
      <ApplicationConfirmed
        :offer="offer"
        @changeOfferState="loadOffer()"
      />
    </div>
    <v-container
      v-else-if="offer_state === 'NOT_FOUND'"
      style="height:600px"
    >
      <v-layout>
        <v-flex
          xs12
        >
          <NotAvailable />
        </v-flex>
      </v-layout>
    </v-container>
    <div class="white">
      <PublicOfferRelatedOffer
        :offer="offer"
        :offers="offer.related_offers"
        :disable-favourite="true"
        @setFavourite="isFavourite => offer.is_favourite = isFavourite"
      />
    </div>
    <div style="background: #ff6625">
      <Footer />
    </div>
  </v-flex>
</template>

<script>
import axios from 'axios';
import { JwtIsExpired } from '@/utils/jwt';
import RSSFeedMixin from '@/components/mixins/RSSFeedMixin.vue';
import DetailBarOffer from '@/components/offers/DetailBarOffer.vue';
import PublicOfferBody from '@/components/offers/PublicOfferBody.vue';
import ApplicationConfirmed from '@/components/offers/ApplicationConfirmed.vue';
import PublicOfferRelatedOffer from '@/components/offers/PublicOfferRelatedOffer.vue';
import Footer from '@/views/Footer.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import Search from '@/components/offers/Search.vue';
import NotAvailable from '@/components/offers/NotAvailable.vue';

export default {
  name: 'Offer',
  components: {
    // Tips,
    DetailBarOffer,
    PublicOfferBody,
    ApplicationConfirmed,
    // OfferNotFound,
    PublicOfferRelatedOffer,
    Footer,
    Search,
    NotAvailable,
  },
  mixins: [
    JWTConfigMixin,
    RSSFeedMixin,
  ],
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      jsonLd: null,
      studies: [],
      offer: {},
      questions: [],
      offers: [],
      offer_state: 'ACTIVE',
      filters: {
        keyword: '',
        region: '',
      },
      reload: false,
    };
  },
  computed: {
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  watch: {
    userIsLoggedIn() {
      this.loadOffer();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOffer();
      this.loadOffers(true);
      this.loadRSSFeed(this.geo);
      this.checkRelatedOfferLinkCount();
      this.gaEvent('Offer Clicked', 'offers', this.uuid, 1);
    });
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
    checkRelatedOfferLinkCount() {
      if (this.$route.query.fromOfferUuid) {
        // add to the related offer count for this pair
        const fromUuid = this.$route.query.fromOfferUuid;
        const toUuid = this.uuid;
        const ep = this.$store.state.endpoints.offers.public.relatedOfferCount(fromUuid, toUuid);
        axios.get(ep).then(response => {
          console.log(response);
        }).catch(error => {
          console.error(error);
        });
      }
    },
    searchOffers() {
      this.$router.push({
        name: 'candidate:offers',
        query: {
          region: this.filters.region,
          keyword: this.filters.keyword,
        },
      });
      this.filters.region = '';
      this.filters.keyword = '';
    },
    loadOffer() {
      this.$store.commit('setLoading');
      const endpoint = this.$store.state.endpoints.offers.public.get(this.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.offer = response.data;
          this.$vuetify.goTo(0, {
            duration: 1000,
            offset: 0,
            easing: 'easeOutCubic',
          });
          this.jsonLd = {
            '@context': 'https://schema.org',
            '@type': 'JobPosting',
            datePosted: this.offer.published_date,
            description: this.offer.description,
            hiringOrganization: {
              '@type': 'Organization',
              name: 'Mandomedio',
              sameAs: 'https://mandomedio.com/',
              logo: '/favicon.png',
            },
            jobLocation: {
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                addressLocality: this.offer.city_name,
                addressCountry: this.offer.country_code,
              },
            },
            title: this.offer.position,
            employmentType: this.offer.contract_type_text,
          };
          const epstudies = `${this.$store.state.endpoints.candidates.studies}?country=${this.offer.country_code}`;
          axios.get(epstudies, this.jwtConfig)
            .then(resp => {
              const prot = resp.data;
              /*
               * This next line takes a key: value Object and inverts it into a value: key
               * Object, which for computing simplicity is used as a Map instead.
               * Reference:
               * https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Object/entries
               */
              this.studies = new Map(Object.entries(prot).map(el => [el[1], el[0]]));
              this.$store.commit('unsetLoading');
              this.questions = this.offer.questions.map(data => {
                const copy = { ...data };
                copy.answer = '';
                return copy;
              });
            });
          this.offer_state = 'ACTIVE';
        }).catch(e => {
          console.log(e.response);
          this.offer_state = 'NOT_FOUND';
        });
    },
    loadOffers() {
      this.$store.commit('setLoading');
      const url = `${this.$store.state.endpoints.offers.public.list}`;
      axios.get(url, { ...this.jwtConfig, params: { ...this.filters_payload } }).then(response => {
        this.nextPage = response.data.next;
        this.prevPage = response.data.previous;
        this.offers = response.data.results;
        this.pages = Math.ceil(response.data.count / response.data.results.length);
        this.$store.commit('unsetLoading');
        this.page = 1;
      });
    },
    changeOfferstate() {
      this.offer_state = 'CONFIRMED';
    },
  },
};
</script>

<style scoped>
.notfound-container{
  height: 500px;
}
</style>
