<template>
  <Offer
    :key="$route.params.uuid"
    :uuid="$route.params.uuid"
  />
</template>

<script>
import Offer from '@/components/offers/Offer.vue';

export default {
  name: 'OfferView',
  components: {
    Offer,
  },
};
</script>
