<template>
  <v-flex>
    <div class="rectangle-2" />
    <v-layout
      align-center
      justify-center
      row
      fill-height
    >
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="rectangle-copy-10"
      >
        <v-layout
          align-center
          justify-center
          row
          fill-height
          mx-2
        >
          <v-text-field
            v-model.trim="filters.keyword"
            single-line
            style="width: 360px"
            label="Puesto, empresa o palabra clave"
            clearable
            hide-details
            full-width
            @keyup.enter="loadOffers()"
          />
          <v-divider
            color="#CCCCCC"
            inset
            vertical
            style="margin-right: 8px; margin-top: 15px; height: 50%;"
          />
          <v-icon small>
            fas fa-map-marker-alt
          </v-icon>
          <RegionPickerV2
            :key="$route.params.geo"
            v-model="filters.region"
            :country="$route.params.geo"
            :disabled="!$route.params.geo"
            single-line
            full-width
            style="margin-top: 28px"
            append-icon=""
          />
          <v-layout
            align-center
            justify-center
            row
            fill-height
          >
            <v-btn
              icon
              @click="loadOffers()"
            >
              <div class="oval-copy" />
              <v-icon
                small
                style="color: #107c86"
              >
                far fa-search
              </v-icon>
            </v-btn>
          </v-layout>
        </v-layout>
      </div>
      <div
        v-else
        :class="$route.name === 'candidate:offers'
          ? 'rectangle-copy-10 mr-4' : 'rectangle-copy-10'"
        style="width: 270px"
      >
        <v-layout
          align-center
          justify-center
          row
          fill-height
          mx-2
        >
          <v-layout
            align-center
            justify-space-between
            row
            fill-height
            @click="search_modal = true"
          >
            <p class="puesto-empresa-o-pa-copy">
              Puesto, empresa o palabra clave
            </p>
            <v-btn
              icon
            >
              <div class="oval-copy" />
              <v-icon
                small
                style="color: #107c86"
              >
                far fa-search
              </v-icon>
            </v-btn>
          </v-layout>
          <v-btn
            v-if="$route.name === 'candidate:offers'"
            icon
            style="position: absolute; margin-left: 325px"
            @click="openDialogFilters()"
          >
            <v-icon
              small
              style="color: rgba(0,0,0,0.6);"
            >
              far fa-sliders-h
            </v-icon>
          </v-btn>
        </v-layout>
        <v-dialog
          v-model="search_modal"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-layout justify-end>
              <v-btn
                icon
                @click="search_modal = false"
              >
                <v-icon>
                  fal fa-times
                </v-icon>
              </v-btn>
            </v-layout>
            <v-card-title class="buscar">
              Buscar
            </v-card-title>
            <v-card-text>
              <v-flex>
                <div class="rectangle-copy-18">
                  <p>
                    <v-text-field
                      v-model.trim="filters.keyword"
                      single-line
                      label="Puesto, empresa o palabra clave"
                      clearable
                      hide-details
                      full-width
                      color="#30B0BF"
                    />
                  </p>
                </div>
              </v-flex>
              <v-flex mt-2>
                <div class="rectangle-copy-18">
                  <v-layout>
                    <v-icon
                      small
                      style="margin-bottom: 30px; margin-left: 15px"
                    >
                      fas fa-map-marker-alt
                    </v-icon>
                    <RegionPickerV2
                      :key="$route.params.geo"
                      v-model="filters.region"
                      :country="$route.params.geo"
                      :disabled="!$route.params.geo"
                      single-line
                      full-width
                      append-icon=""
                    />
                  </v-layout>
                </div>
              </v-flex>
              <v-flex mt-4>
                <v-btn
                  block
                  large
                  color="#E4F5F7"
                  class="rectangle elevation-0"
                  @click="loadOffers(); search_modal = false;"
                >
                  <p class="buscar-trabajo">
                    Buscar  trabajo
                  </p>
                </v-btn>
              </v-flex>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-layout>
    <v-dialog
      v-model="success_modal"
      max-width="432px"
      persistent
    >
      <v-card style="border-radius: 6px">
        <v-container>
          <v-layout
            justify-center
            row
            wrap
          >
            <v-flex
              xs12
              mt-5
            >
              <div align="center">
                <v-img
                  :src="require('@/assets/verification_success.png')"
                  width="128px"
                  style="text-align: center"
                />
              </div>
            </v-flex>
            <v-flex xs12>
              <p class="account-validated">
                Cuenta validada
              </p>
            </v-flex>
            <v-flex xs12>
              <p
                class="expired-text"
                :style="$vuetify.breakpoint.smAndUp
                  ? 'width: 350px;margin-left: 15px;' : ''"
              >
                Tu cuenta Mandomedio fue validada
                exitosamente
              </p>
            </v-flex>
            <v-flex
              xs12
              style="margin-bottom: 70px"
            >
              <v-btn
                style="border-radius: 5px;height: 50px;"
                block
                color="info2"
                @click="continueLogin()"
              >
                <p class="button-text">
                  Continuar
                </p>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import axios from 'axios';
import { parseJwt } from '@/utils/jwt';
import RegionPickerV2 from '@/components/inputs/RegionPickerV2.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'Search',
  components: {
    RegionPickerV2,
  },
  mixins: [
    JWTConfigMixin,
  ],
  props: {
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      search_modal: false,
      success_modal: false,
      emailErrors: [],
      email: '',
      token: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.token) {
        axios.put(this.$store.state.endpoints.offers.admin.activateAccount,
          { token: this.$route.query.token }, this.jwtConfig)
          .then(resp => {
            this.success_modal = true;
            this.token = resp.data.token;
            this.$router.replace({ query: null });
          })
          .catch(err => {
            console.log(err.response.status, 'ERROR');
            if (err.response.status === 406) {
              this.token = err.response.data.token;
              this.$router.replace({ query: null });
              this.$store.commit('adminSnackbarUp', err.response.data.response);
              this.continueLogin();
            } else {
              this.$router.push({ name: 'link:expired' });
            }
          });
      }
    });
  },
  methods: {
    loadOffers() {
      this.$emit('loadOffers');
    },
    openDialogFilters() {
      this.$emit('openDialogFilters');
    },
    sendEmail() {
      const endpoint = this.$store.state.endpoints.offers.admin.resendVerification;
      const payload = {
        email: this.email,
        url: this.$route.fullPath,
      };
      axios.post(endpoint, payload, this.jwtConfig)
        .then(resp => {
          console.log(resp, 'RESEND');
          this.expired_modal = false;
          this.$store.commit('adminSnackbarUp', resp.data.response);
        })
        .catch(err => {
          console.log(err, 'ERROR RESEND');
        });
    },
    continueLogin() {
      this.$store.commit('updateToken', this.token);
      this.token = '';
      if (!this.$store.state.isAdmin) {
        axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
          .then(res => {
            this.$store.commit('setLoginDetails', res.data);
            this.$store.commit('unsetLoading');
            this.$emit('loginSuccess', '');
            this.success_modal = false;
          });
      } else {
        this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
        this.$store.commit('unsetLoading');
        this.$emit('loginSuccess', '');
        this.success_modal = false;
      }
    },
  },
};
</script>

<style scoped>
  .rectangle-2 {
    height: 104px;
    width: auto;
    background-color: #FFFFFF;
  }
  .rectangle-copy-10 {
    height: 56px;
    width: 580px;
    border-radius: 47.5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.17);
    position: absolute;
    top: 0;
    margin-top: 25px;
    opacity: 0.9;
  }
  .puesto-empresa-o-pa-copy {
    margin-top: 17px;
    margin-left: 7px;
    color: rgba(174,174,174,0.87);
    font-size: 13.2px;
    letter-spacing: 0.15px;
    line-height: 28px;
  }
  .oval-copy {
    height: 44px;
    width: 44px;
    opacity: 0.23;
    border-radius: 50%;
    background-color: #30B0BF;
    position: absolute;
  }
  .buscar {
    height: 25.2px;
    width: 221.48px;
    margin-bottom: 15px;
    color: rgba(0,0,0,0.87);
    font-family: Lato;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.2px;
    line-height: 25.2px;
  }
  .rectangle-copy-18 {
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 6px;
    height: 55px;
  }
  .rectangle {
    border-radius: 6px;
    background-color: #E4F5F7;
  }
  .buscar-trabajo {
    height: 24px;
    width: 99px;
    color: #30B0BF;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    margin-top: 15px;
    text-transform: initial;
  }
  .account-validated {
  color: #222B45;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
    text-align: center;
    margin-top: 20px;
}
  .expired-text {
  height: 71px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-align: center;
}
  .inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
  .button-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
</style>
