<template>
  <v-container
    fluid
    style="
        background: linear-gradient(0deg, #E5662E 0%, #E5913D 99.3%, #E5913D 100%);
        padding: 10px;"
  >
    <div
      :style="$vuetify.breakpoint.smOnly
        ? 'max-width: 70%;margin-left: 15%' : ''"
    >
      <v-layout wrap>
        <v-flex
          v-if="lastRouteName()"
          xs12
          md7
          offset-md2
          @click="onBackPressed"
        >
          <v-btn
            icon
            x-small
            flat
            color="white lighten-2"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-left: 20px' : ''"
          >
            <v-icon
              small
            >
              far fa-chevron-left
            </v-icon>
            <p class="text-back">
              Volver
            </p>
          </v-btn>
        </v-flex>
        <v-flex
          v-else
          xs12
          md7
          offset-md2
        >
          <v-btn
            icon
            x-small
            flat
            color="white lighten-2"
            :style="$vuetify.breakpoint.smAndDown ? 'margin-left: 20px' : ''"
            @click="$router.push({name: 'candidate:offers'})"
          >
            <v-icon small>
              far fa-chevron-left
            </v-icon>
            <p class="text-back">
              Volver
            </p>
          </v-btn>
        </v-flex>
        <v-flex
          v-if="$vuetify.breakpoint.mdAndUp"
          md1
        >
          <div style="display: inline-flex">
            <p class="text-save">
              Guardar
            </p>
            <FavouriteButton
              :offer="offer"
              :color="color"
              @setFavourite="isFavourite => $emit('setFavourite', isFavourite)"
            />
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex
          offset-lg1
          mt-2
          :style="$vuetify.breakpoint.mdOnly ? 'margin-left:58px;' : ''"
        >
          <v-avatar
            style="border-radius: 5px;margin-left: 10px;margin-bottom: 12px"
            color="#444444"
            :size="$vuetify.breakpoint.mdAndUp ? '78' : '48'"
            tile
          >
            <v-icon
              :x-large="$vuetify.breakpoint.mdAndUp"
              color="white"
            >
              {{ jobIcon() }}
            </v-icon>
          </v-avatar>
        </v-flex>
        <v-flex
          xs12
          md6
          lg6
          ml-2
        >
          <p
            class="title-offer"
          >
            {{ offer.computed_title }}
          </p>
          <v-layout>
            <p
              class="subtitle-offer"
            >
              {{ offer.region_name }},
              {{ offer.city_name }} -
            </p>
            <p
              class="text-contract-type"
            >
              {{ contractType() }}
            </p>
          </v-layout>
          <p
            class="subtitle-offer"
          >
            {{ offer.area_name }}
          </p>
          <v-layout
            v-for="(perk, index) in offer.perks"
            :key="`${perk}-${index}`"
            style="display: inline;"
          >
            <v-icon
              small
              color="white"
              style="margin-right: 10px;margin-top: 5px"
            >
              {{ perk.icon }}
            </v-icon>
          </v-layout>
          <p class="publish-text">
            Publicado {{ offer.time_since_created }}
          </p>
        </v-flex>
        <v-flex
          md4
          lg4
          :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 67px' : ''"
        >
          <v-layout justify-start>
            <ApplyButton
              :offer="offer"
              @updatedOffer="(updatedOffer) => $emit('updatedOffer', updatedOffer)"
            />
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout :ml-2="$vuetify.breakpoint.smAndDown">
        <v-flex
          xs12
          md7
          offset-md2
        >
          <v-layout
            wrap
          >
            <p class="share-text">
              Comparte
            </p>
            <a
              style="margin-top: 19px"
              @click="shareOffer('LinkedIn', 'https://www.linkedin.com/company/mandomedio/')"
            >
              <img
                src="@/assets/linkedin.svg"
                alt="logo"
                style="margin-left: 10px"
              >
            </a>
            <a
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-social"
              @click="shareOffer('LinkedIn', 'https://www.linkedin.com/company/mandomedio/')"
            >
              LinkedIn
            </a>
            <a
              style="margin-top: 19px"
              @click="shareOffer('Facebook', 'https://www.facebook.com/MandomedioLtm/')"
            >
              <img
                src="@/assets/facebook.svg"
                alt="logo"
                style="margin-left: 10px"
              >
            </a>
            <a
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-social"
              @click="shareOffer('Facebook', 'https://www.facebook.com/MandomedioLtm/')"
            >
              Facebook
            </a>
            <a
              style="margin-top: 19px"
              @click="shareOffer('Instagram', 'https://www.instagram.com/mandomedio_com/')"
            >
              <img
                src="@/assets/instagram.svg"
                alt="logo"
                style="margin-left: 10px"
              >
            </a>
            <a
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-social"
              @click="shareOffer('Instagram', 'https://www.instagram.com/mandomedio_com/')"
            >
              Instagram
            </a>
            <a
              style="margin-top: 19px"
              @click="shareOffer('Twitter', 'https://twitter.com/Mandomedio_com')"
            >
              <img
                src="@/assets/twitter.svg"
                alt="logo"
                style="margin-left: 10px"
              >
            </a>
            <a
              v-if="$vuetify.breakpoint.mdAndUp"
              class="text-social"
              @click="shareOffer('Twitter', 'https://twitter.com/Mandomedio_com')"
            >
              Twitter
            </a>
          </v-layout>
        </v-flex>
        <v-flex
          v-show="!$vuetify.breakpoint.mdAndUp"
          md1
          style="margin-top: 6px"
        >
          <div style="display: inline-flex">
            <p class="text-save">
              Guardar
            </p>
            <FavouriteButton
              :offer="offer"
              :color="color"
              @setFavourite="isFavourite => $emit('setFavourite', isFavourite)"
            />
          </div>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import FavouriteButton from '@/components/candidates/FavouriteButton.vue';
import ApplyButton from '@/components/offers/ApplyButton.vue';

export default {
  name: 'DetailBarOffer',
  components: {
    FavouriteButton,
    ApplyButton,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.lastRouteName();
    });
  },
  methods: {
    gaEvent($action, $category, $label, $value) {
      this.$ga.event($action, $category, $label, $value);
    },
    onBackPressed() {
      this.gaEvent('Back Button Offer', 'offers', this.offer.uuid, 1);
      this.$router.back();
    },
    shareOffer(name, link) {
      let action = 'Share Offer ';
      action += name;
      this.gaEvent(action, 'offers', this.offer.uuid, 1);
      window.open(link, '_blank');
    },
    jobIcon() {
      if (!this.offer) {
        return 'fal fa-circle-notch fa-spin';
      }
      const iconDict = {
        'Operaciones y Logística': 'fal fa-dolly',
        'Administración y Finanzas': 'fal fa-analytics',
        RRHH: 'fal fa-id-badge',
        Comercial: 'fal fa-briefcase',
        Marketing: 'fal fa-lightbulb-on',
        Tecnología: 'fal fa-phone-laptop',
        Seguridad: 'fal fa-shield-check',
      };
      return iconDict[this.offer.transformed_area] ? iconDict[this.offer.transformed_area]
        : 'fal fa-question-circle';
    },
    contractType() {
      const contractTypeDict = {
        FULL_TIME: 'Full time',
        PART_TIME: 'Part time',
        CONTRACTOR: 'Honorarios',
        TEMPORARY: 'Temporal plazo fijo',
        INTERN: 'Práctica profesional',
        VOLUNTEER: 'Voluntario',
        PER_DIEM: 'Por día',
        OTHER: 'Otros',
      };
      return contractTypeDict[this.offer.contract_type_text];
    },
    lastRouteName() {
      return window.history.state;
    },
  },
};
</script>

<style scoped>
.text-back {
  width: 47px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: capitalize;
  margin-left: 5px;
  margin-top: 17px;
}
.text-save {
  height: 28px;
  width: 64px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 28px;
  text-transform: capitalize;
  margin-top: 8px;
}
.title-offer {
  min-height: 15px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  margin: 0px;
}
.subtitle-offer {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.publish-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.text-contract-type {
  color: rgba(255,255,255,0.7);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin-left: 2px;
  margin: 0px;
}
.share-text {
  width: 77px;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 28px;
  text-transform: capitalize;
  margin-top: 13px;
}
.text-social {
  height: 28px;
  width: 62px;
  opacity: 0.44;
  color: #FFFFFF;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 28px;
  text-transform: capitalize;
  text-decoration: none;
  margin-left: 5px;
  margin-right: 30px;
  margin-top: 13px;
}
</style>
