<template>
  <v-container
    fluid
    style="background-color: #FFFFFF"
  >
    <v-layout
      row
      wrap
    >
      <v-flex :style="sizeMargin($vuetify.breakpoint)">
        <v-layout>
          <v-icon
            medium
            style="color: #212121;margin-right: 24px"
          >
            fal fa-file-alt
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'text-title' : 'text-title-responsive'"
          >
            Descripción de la Empresa
          </p>
        </v-layout>
        <p
          :class="$vuetify.breakpoint.smAndUp ? 'text-description' : 'text-description-responsive'"
        >
          {{ offer.summary }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="offer.objectives !== ''"
      row
      wrap
    >
      <v-flex :style="sizeMargin($vuetify.breakpoint)">
        <v-layout>
          <v-icon
            medium
            style="color: #212121;margin-right: 20px"
          >
            fal fa-bullseye-arrow
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'text-title' : 'text-title-responsive'"
          >
            Objetivo
          </p>
        </v-layout>
        <p
          :class="$vuetify.breakpoint.smAndUp ? 'text-description' : 'text-description-responsive'"
          style="margin-left: 48px"
        >
          {{ offer.objectives }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex :style="sizeMargin($vuetify.breakpoint)">
        <v-layout>
          <v-icon
            medium
            style="color: #212121;margin-right: 20px"
          >
            fal fa-cog
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'text-title' : 'text-title-responsive'"
          >
            Funciones
          </p>
        </v-layout>
        <p
          :class="$vuetify.breakpoint.smAndUp ? 'text-description' : 'text-description-responsive'"
          style="margin-left: 48px"
        >
          <vue-markdown>{{ offer.description }}</vue-markdown>
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex :style="sizeMargin($vuetify.breakpoint)">
        <v-layout>
          <v-icon
            medium
            style="color: #212121;margin-right: 15px"
          >
            fal fa-user-cog
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'text-title' : 'text-title-responsive'"
          >
            Requisitos y habilidades
          </p>
        </v-layout>
        <p
          :class="$vuetify.breakpoint.smAndUp ? 'text-description' : 'text-description-responsive'"
          style="margin-left: 48px"
        >
          <vue-markdown>{{ offer.requirements }}</vue-markdown>
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="offer.perks.length > 0"
      row
      wrap
      :style="sizeMargin($vuetify.breakpoint)"
    >
      <v-flex xs12>
        <v-layout style="margin-bottom: 25px;">
          <v-icon
            medium
            style="color: #212121;margin-right: 20px;"
          >
            fal fa-thumbs-up
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'text-title' : 'text-title-responsive'"
          >
            Beneficios de la empresa
          </p>
        </v-layout>
      </v-flex>
      <v-flex
        v-for="(perk, index) in offer.perks"
        :key="`${perk}-${index}`"
        xs12
        md4
        :style="$vuetify.breakpoint.xsOnly
          ? 'max-width: 400px;margin-left: 8px;margin-bottom: 30px'
          : 'max-width: 400px;margin-left: 48px;margin-bottom: 30px'"
      >
        <v-layout>
          <v-icon
            color="#EE8146"
            style="width: 24px"
          >
            {{ perk.icon }}
          </v-icon>
          <p
            :class="$vuetify.breakpoint.smAndUp ? 'perk-title' : 'perk-title-responsive'"
          >
            {{ perk.name }}
          </p>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-flex
      xs12
      style="margin-top: 28px"
    >
      <v-layout justify-center>
        <ApplyButton
          :offer="offer"
          @updatedOffer="(updatedOffer) => $emit('updatedOffer', updatedOffer)"
        />
      </v-layout>
    </v-flex>
  </v-container>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';
import ApplyButton from '@/components/offers/ApplyButton.vue';

export default {
  name: 'PublicOfferBody',
  components: {
    ApplyButton,
    VueMarkdown,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      marginLeft: '',
    };
  },
  methods: {
    sizeMargin(breakpoint) {
      if (breakpoint.xs === true) this.marginLeft = '';
      if (breakpoint.sm === true) this.marginLeft = 'margin-left: 9.5%';
      if (breakpoint.md === true) this.marginLeft = 'margin-left: 11.5%';
      if (breakpoint.lg === true) this.marginLeft = 'margin-left: 12.7%';
      return this.marginLeft;
    },
  },
};
</script>

<style>
.text-title {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  margin-top: 17px;
}
.text-title-responsive {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 17px;
}
.text-description {
  max-width: 933px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-left: 45px;
}
.text-description-responsive {
  max-width: 460px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 24px;
  margin-left: 45px;
}
.perk-title {
  height: 32px;
  width: 215px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
  margin-left: 16px;
}
.perk-title-responsive {
  height: 32px;
  width: 154px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1px;
  line-height: 32px;
  margin: 0px;
  margin-left: 16px;
}
</style>
