<template>
  <v-btn
    color="#30B0BF"
    class="postulate elevation-0"
    :dark="!userIsApplying"
    :disabled="userIsApplying"
    @click="openApplyDialog"
  >
    <template
      v-if="userIsApplying"
    >
      <p class="text-postulate">
        Ya estas postulando
      </p>
    </template>
    <template v-else>
      <p class="text-postulate">
        Postular a este empleo
      </p>
    </template>
    <v-dialog
      v-model="new_user_warning_dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>¡No te vayas todavía!</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Tu cuenta ya fue creada, sólo falta que respondas algunas preguntas para
          completar tu postulación
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            @click="new_user_warning_dialog = false;"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="login_dialog"
      max-width="432"
      style="margin: 0px;"
      persistent
    >
      <LoginCard
        @closeLogin="login_dialog = false"
        @loginSuccess="loginSuccess"
        @changeModal="signup_modal = true; login_dialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      style="margin: 0px"
      persistent
    >
      <CandidateSignup
        @signupDone="finishSignup"
        @closeModal="signup_modal = false;"
        @changeModal="signup_modal = false; login_dialog = true"
      />
    </v-dialog>
    <v-dialog
      v-model="already_applying_warning"
      width="500"
    >
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <h3>
              Ya estas postulando.
            </h3>
          </v-layout>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import axios from 'axios';
import { JwtIsExpired } from '@/utils/jwt';
import RSSFeedMixin from '@/components/mixins/RSSFeedMixin.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import LogoutMethodMixin from '@/components/mixins/auth/LogoutMethodMixin.vue';
import UserIsLoggedComputedMixin from '@/components/mixins/auth/UserIsLoggedComputedMixin.vue';
import AuthenticatePayloadMethodMixin from '@/components/mixins/auth/AuthenticatePayloadMethodMixin.vue';
import LoginCard from '@/components/forms/LoginCard.vue';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';

export default {
  name: 'ApplyButton',
  components: {
    LoginCard,
    CandidateSignup,
  },
  mixins: [
    JWTConfigMixin,
    RSSFeedMixin,
    LogoutMethodMixin,
    UserIsLoggedComputedMixin,
    AuthenticatePayloadMethodMixin,
  ],
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      new_user_warning: false,
      new_user_warning_dialog: false,
      applying: false,
      already_applying_warning: false,
      login_dialog: false,
      signup_modal: false,
    };
  },
  computed: {
    userIsApplying() {
      return this.offer.user_is_applying;
    },
    userIsLoggedIn() {
      return !JwtIsExpired(this.$store.state.jwt);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.verifyPostulation();
    });
  },
  methods: {
    finishSignup() {
      this.signup_modal = false;
      this.$emit('signupDone', '');
    },
    verifyPostulation() {
      const endpoint = this.$store.state.endpoints.offers.public.get(this.$route.params.uuid);
      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.offer = response.data;
          this.$store.commit('unsetLoading');
        });
    },
    loginSuccess() {
      this.signup_modal = false;
      this.login_dialog = false;
      this.$store.commit('setLoading');
      const endpoint = this.$store.state.endpoints.offers.public.get(this.$route.params.uuid);

      axios.get(endpoint, this.jwtConfig)
        .then(response => {
          this.$store.commit('unsetLoading');
          // decide which dialog to show
          const updateOffer = response.data;
          this.$emit('updatedOffer', updateOffer);
          if (this.new_user_warning) {
            this.new_user_warning_dialog = true;
          } else if (updateOffer.user_is_applying) {
            // if user is already applying, show warning message
            this.already_applying_warning = true;
          } else {
            this.$router.push({ name: 'candidate:offer:postulation' });
          }
        });
    },
    openApplyDialog() {
      this.$store.commit('setLoading');
      this.$ga.event('postulacion', 'quieroPostular', `${this.offer.uuid}`, 1);
      if (!this.$store.state.jwt) {
        this.login_dialog = true;
      } else {
        this.$router.push({ name: 'candidate:offer:postulation' });
      }
      this.$store.commit('unsetLoading');
    },
  },
};
</script>

<style scoped>
.social_row {
  text-align: center;
}
.postulate {
  height: 42px;
  width: 212px;
  border-radius: 6px;
  background-color: rgba(0,0,0,0);
}
.text-postulate {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin: 0px;
  text-transform: initial;
}
</style>
